/*General Styles*/
* {
  font-family: Optima, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
    sans-serif;
  font-size: 18px;
  color: rgb(211, 210, 210);
  background-color: #272e42;
}

.App {
  text-align: center;
  margin-bottom: 30px;
}

h1 {
  font-size: 75px;
  padding: 0px;
  margin: 0px;
}

h2 {
  font-size: 30px;
  margin: 0px;
}

li {
  text-align: left;
  list-style-position: inside;
}

ul {
  text-align: left;
  list-style-position: inside;
  margin: 0px;
}

a {
  text-decoration: none;
}

.slide {
  flex-shrink: 0;
  width: 100%;
  scroll-snap-align: start;
}

.sliderGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(211, 210, 210);
  margin: 1em auto;
  margin-bottom: 0px;
  padding: 0;
  align-content: center;
  width: 10%;
}

.link {
  margin: 10px;
  padding: 5px;
  border-radius: 30%;
  text-decoration: none;
  color: rgb(103, 197, 103);
}
.link:hover {
  background-color: rgb(103, 197, 103);
  border-radius: 30%;
  color: #272e42;
  font-weight: bold;
}
.linkGroup {
  position: relative;
  animation: move-links;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  margin: 5px;
}

.linkToProjectGroup {
  margin: 10px;
  margin-bottom: 25px;
}
.linkToProject {
  padding: 5px 5px 5px 5px;
  background-color: rgb(103, 197, 103);
  border-radius: 5%;
  color: #282c34;
  text-decoration: none;
  font-weight: bold;
}
.linkToProject:hover {
  padding: 10px 10px 10px 10px;
  border-radius: 5%;
}

.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*Landing Page styles*/
.pronouns {
  font-size: 10px;
  padding-left: 20px;
}

.social {
  border: solid;
  border-radius: 30%;
  border-color: rgb(103, 197, 103);

  font-size: 20px;
  padding: 7px 10px 7px 10px;
  margin: 5px;
}
.social:hover {
  padding: 12px 15px 12px 15px;
}

.socialGroup {
  position: relative;
  animation: move-social;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  margin: 35px;
}

@keyframes move-social {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes move-links {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}

/* Projects page styles*/
.projectsGroup {
  display: flex;
  flex-direction: column;
  margin: 0px 25px 25px 25px;
}

/* mobile styles */
@media (max-width: 812px) {
  /* landing page styles*/
  .summary {
    width: 80%;
    margin: 30px auto 30px auto;
    text-align: left;
  }

  /* About Me styles */
  .profilePic {
    border-radius: 10%;
    margin: 30px;
    width: 40%;
  }
  /* general projects styles */
  .projectPageContainer {
    width: 90%;
    margin: auto;
  }

  .technologyList {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    align-items: center;
    padding: 25px;
  }

  /* reddalerts styles */
  .reddalertsPhoto {
    width: 90%;
  }

  .reddalerts-slider {
    width: 100%;
    display: flex; /*horizontal scrolling*/
    margin: auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  /* explorers connect styles */
  .explorersPhoto {
    width: 90%;
  }

  .explorers-slider {
    width: 100%;
    display: flex; /*horizontal scrolling*/
    margin: auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
}

/* desktop styles */
@media (min-width: 813px) {
  /* landing page styles*/
  .summary {
    width: 700px;
    margin: 30px auto 30px auto;
    text-align: left;
  }

  /* About Me styles */
  .profilePic {
    border-radius: 10%;
    margin: 30px;
    width: 30%;
    max-width: 350px;
  }

  /* general projects style */
  .projectPageContainer {
    width: 700px;
    margin: auto;
  }

  .technologyList {
    display: flex;
    flex-direction: column;
    width: 700px;
    align-content: center;
    align-items: center;
    padding: 25px;
  }

  /* reddalerts styles */
  .reddalertsPhoto {
    width: 700px;
    height: 400px;
  }

  .reddalerts-slider {
    width: 700px;
    height: 420px;
    display: flex; /*horizontal scrolling*/
    margin: auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  /* explorers connect styles*/
  .explorers-slider {
    width: 700px;
    height: 570px;
    display: flex; /*horizontal scrolling*/
    margin: auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
  .explorersPhoto {
    width: 700px;
    height: 550px;
  }
}
